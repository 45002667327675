import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet';

import purpleOval from './media/roundedRect.png';
import AlaskaAirlines from './media/companyLogos/alaskaAirlines.svg';
import BoysAndGirlsClubs from './media/companyLogos/boysAndGirlsClubs.svg';
import CallToAction from '../../components/CallToAction';
import FAQGoose2 from './media/faqGoose2.svg';
import GameplayAccordion from './components/GameplayAccordion';
import GE from './media/companyLogos/GE.svg';
import Grid from '../../components/layout/Grid';
import K12 from './media/k12.svg';
import LicensesAccordion from './components/LicensesAccordion';
import Link from '../../components/Link';
import Microsoft from './media/companyLogos/microsoft.svg';
import NPO from './media/npo.svg';
import Page, { Section } from '../../components/Page';
import RequestQuoteForm from '../../components/RequestQuoteForm';
import routes from '../../routes';
import styles from './Pricing.module.scss';
import TestimonyCard from './components/TestimonyCard';
import TestimonyCardCarousel from './components/TestimonyCardCarousel';
import utilStyles from '../../styles/util.module.scss';
import YaleSchoolOfManagement from './media/companyLogos/yaleSchoolOfManagement.svg';
import { segmentTrackEvent, trackEvent } from '../../services/analyticsService';
import { isSegmentEnabled } from '../../services/featureFlagService';
import Flex from '../../components/layout/Flex';
import PriceTiersSection from './components/PriceTiersSection';
import PriceTier from './components/PriceTier';
import { pricingFAQ, pricingReqQuoteFormId } from '../../services/pricingFAQService';

function getNumTeamsMainFeatures(numTeams: number) {
  return [`Up to ${numTeams} teams in Team mode`, `Up to ${numTeams} participants in Solo mode`];
}

const Pricing = () => {
  const [activeTestimonyCardNum, setActiveTestimonyCardNum] = useState(0);
  const requestQuoteSectionWrapperRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const onRequestQuoteClick = (e: React.MouseEvent, source: 'enterprise_CTA' | 'NPO_CTA') => {
    if (requestQuoteSectionWrapperRef.current) {
      e.preventDefault();
      requestQuoteSectionWrapperRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    const utm_medium = 'marketing_site';
    const utm_source = 'pricing_page';

    history.push({
      search: `?utm_medium=${utm_medium}&utm_source=${utm_source}&utm_campaign=subscriptions_above_fold-${source}`,
      hash: `#${pricingReqQuoteFormId}`,
    });

    if (isSegmentEnabled()) {
      segmentTrackEvent('Quote request CTA clicked', {
        source,
        medium: utm_medium,
        page: utm_source,
      });
    } else {
      trackEvent({
        event: 'analytics_event',
        event_name: 'quote_request_cta_clicked',
        pretty_name: 'Quote request CTA clicked',
        event_common_attributes: {
          source,
          medium: utm_medium,
          page: utm_source,
        },
      });
    }
  };

  return (
    <Page
      title="Goosechase Pricing & Plans | Goosechase"
      description="From annual subscriptions to single Goosechase Experience licenses & special pricing for educators and NPOs, we ensure you can run the Experience you want. Try it for free!"
    >
      <Helmet>
        {/* For structured schema */}
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'FAQPage',
            mainEntity: pricingFAQ.licenses
              .map((qAndA) => ({
                '@type': 'Question',
                name: qAndA.q,
                acceptedAnswer: {
                  '@type': 'Answer',
                  text: qAndA.aStringMarkup || qAndA.a,
                },
              }))
              .concat(
                pricingFAQ.experiences.map((qAndA) => ({
                  '@type': 'Question',
                  name: qAndA.q,
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: qAndA.aStringMarkup || qAndA.a,
                  },
                }))
              ),
          })}
        </script>
      </Helmet>

      <Section className={utilStyles.textCenter} paddingTop="md" paddingBot="sm">
        <h1 className={styles.title}>Get started for free</h1>
        <p className={styles.subtitle}>
          If you haven't already, start by creating your first Goosechase Experience. You can upgrade it at any time.
        </p>
        <CallToAction />
      </Section>

      <PriceTiersSection
        bg="green"
        calloutProps={{
          description:
            'Need bigger Goosechase Experiences? More of them? And more often? A Goosechase subscription might be right for you: get unlimited access for you, your team, and your entire organization.',
          linkHref: routes.WHY_BUY_A_SUBSCRIPTION.path,
          iconType: 'subsYellow',
        }}
        priceTiers={[
          {
            title: 'Recreational',
            borderTopColor: 'none',
            price: '$0',
            priceDetails: (
              <div>
                USD per
                <br />
                Experience
              </div>
            ),
            ctaLink: (
              <PriceTier.CtaLink genericCta variant="btnSm">
                Try for free
              </PriceTier.CtaLink>
            ),
            mainFeaturesHeight: 'tall',
            mainFeatures: getNumTeamsMainFeatures(3),
            secondaryFeatures: [
              'Run 1 live Experience at a time',
              'Up to 5 mobile devices per team',
              'Unlimited Missions',
              'Access to 250+ pre-built Experience templates',
              'Real-time activity feed',
              'Automatic scoring and leaderboard',
              'In-app creator-to-participant messaging',
              'Submission downloads',
              'Support from the customer success team',
            ],
          },
          {
            title: 'Starter',
            borderTopColor: 'lilac',
            price: '$399',
            priceDetails: (
              <div>
                USD per
                <br />
                Experience
              </div>
            ),
            ctaLink: (
              <PriceTier.CtaLink genericCta variant="btnSmOutline">
                Get started
              </PriceTier.CtaLink>
            ),
            mainFeaturesHeight: 'tall',
            mainFeatures: getNumTeamsMainFeatures(8),
            secondaryFeatures: [
              'License for 1 Experience',
              'Unlimited Missions',
              'Access to 250+ pre-built Experience templates',
              'Real-time activity feed',
              'Automatic scoring and leaderboard',
              'In-app creator-to-participant messaging',
              'Submission downloads',
              'Support from the customer success team',
            ],
          },
          {
            title: 'Professional',
            borderTopColor: 'cyan',
            price: '$649',
            priceDetails: (
              <div>
                USD per
                <br />
                Experience
              </div>
            ),
            ctaLink: (
              <PriceTier.CtaLink genericCta variant="btnSmOutline">
                Get started
              </PriceTier.CtaLink>
            ),
            mainFeaturesHeight: 'tall',
            mainFeatures: getNumTeamsMainFeatures(20),
            secondaryFeatures: [
              'License for 1 Experience',
              'Unlimited Missions',
              'Access to 250+ pre-built Experience templates',
              'Real-time activity feed',
              'Automatic scoring and leaderboard',
              'In-app creator-to-participant messaging',
              'Submission downloads',
              'Support from the customer success team',
            ],
          },
          {
            title: 'Enterprise',
            borderTopColor: 'pale-green',
            price: "Let's talk",
            priceIsWords: true,
            ctaLink: (
              <PriceTier.CtaLink
                href={`#${pricingReqQuoteFormId}`}
                variant="btnSmOutline"
                onClick={(e) => onRequestQuoteClick(e, 'enterprise_CTA')}
              >
                Get started
              </PriceTier.CtaLink>
            ),
            mainFeaturesHeight: 'tall',
            mainFeatures: ['Access to larger Goosechase Experiences and annual subscriptions'],
            secondaryFeatures: [
              'Unlimited creator accounts',
              'Tailored support from a dedicated account manager',
              'Unlimited simultaneous live Experiences',
              'Unlimited Missions',
              'Access to 250+ pre-built Experience templates',
              'Real-time activity feed',
              'Automatic scoring and leaderboard',
              'In-app creator-to-participant messaging',
              'Submission downloads',
            ],
          },
        ]}
      />

      <Section>
        <div className={styles.otherPricingWrapper}>
          <Grid container className={styles.otherPricingGrid}>
            <Grid item sm="auto" xs={12}>
              <K12 width="9.6rem" />
              <h2 className={styles.otherPricingTitle}>For K-12 educators</h2>
              <p className={styles.otherPricingDesc}>
                Use Goosechase in your classroom, PD Days, and for school-wide events. We happily support administrators
                and teachers with special subscription pricing.
              </p>
              <div className={styles.linkWrapper}>
                <Link href={routes.EDU_PRICING.path} useRouter variant="underline">
                  K-12 education pricing
                </Link>
              </div>
              <div className={styles.linkWrapper}>
                <Link href={routes.K12_EDUCATION_USES.path} useRouter variant="underline">
                  Learn about Goosechase EDU
                </Link>
              </div>
            </Grid>
            <Grid item sm="auto" xs={12}>
              <NPO width="9.6rem" className={styles.npoIcon} />
              <h2 className={styles.otherPricingTitle}>Non-profit pricing</h2>
              <p className={styles.otherPricingDesc}>
                We love to work with non-profit groups, civic organizations, and municipalities for fundraisers and
                community events. Get in touch to find out more about how Goosechase can support you and your community.
              </p>
              <div className={styles.linkWrapper}>
                <Link
                  href={`#${pricingReqQuoteFormId}`}
                  variant="underline"
                  onClick={(e) => onRequestQuoteClick(e, 'NPO_CTA')}
                >
                  Contact us for NPO pricing
                </Link>
              </div>
            </Grid>
          </Grid>
        </div>
      </Section>

      <Section
        css={{
          backgroundImage: `url('${purpleOval}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          marginBottom: '4rem',
        }}
      >
        <h2 className={styles.testimonyTitle}>Join the thousands of organizations already using Goosechase</h2>
        <Grid container alignItems="center" justify="space-around">
          <Grid item md="auto" xs={6}>
            <YaleSchoolOfManagement className={styles.yaleLogo} />
          </Grid>
          <Grid item md="auto" xs={6}>
            <AlaskaAirlines className={styles.alaskaLogo} />
          </Grid>
          <Grid item md="auto" xs={12}>
            <Microsoft className={styles.microsoftLogo} />
          </Grid>
          <Grid item md="auto" xs={6}>
            <GE className={styles.geLogo} />
          </Grid>
          <Grid item md="auto" xs={6}>
            <BoysAndGirlsClubs className={styles.boysAndGirlsLogo} />
          </Grid>
        </Grid>
      </Section>

      <div className={styles.faqBlock}>
        <Grid container>
          <Grid item md={4} xs={12}>
            <Flex flexDirection="column" justifyContent="space-between">
              <h2 className={styles.commonQuestions}>Common Questions</h2>
              <FAQGoose2 className={styles.faqGoose} width="33rem" />
            </Flex>
          </Grid>
          <Grid item md={8} xs={12}>
            <div className={styles.questionsContainer}>
              <h3 className={styles.questionSectionTitle}>Licenses</h3>
              <LicensesAccordion />

              <h3 className={styles.gameplay}>Gameplay</h3>
              <GameplayAccordion />
            </div>
          </Grid>
        </Grid>
      </div>

      <Section containerProps={{ maxWidth: 'none', disablePadding: true }}>
        <h2 className={styles.storiesTitle}>Goosechase stories</h2>
        <TestimonyCardCarousel onActiveSlideChange={(num) => setActiveTestimonyCardNum(num)}>
          <TestimonyCard
            active={activeTestimonyCardNum === 0}
            quote="I think companies realize this is a good way to keep their folks authentically connected and happy."
            author="Luke Talbott"
            organization="Compass Outdoor Adventures"
            color="purple"
          />
          <TestimonyCard
            active={activeTestimonyCardNum === 1}
            quote="I just have to get people to laugh together. That comes through with Goosechase. It’s wonderful, and it’s another whole level of camaraderie."
            author="Janet Carmody"
            organization="Callyo"
            color="purple"
          />
          <TestimonyCard
            active={activeTestimonyCardNum === 2}
            quote="It’s amazing how simple it is, but that you can have that whirlwind of engagement, fun, and community building to get people connected."
            author="Jonathan Tomines"
            organization="The Toe Bro"
            color="purple"
          />
          <TestimonyCard
            active={activeTestimonyCardNum === 3}
            quote="We plan to use Goosechase again, and I have already started to recommend it to other firms we have relationships with."
            author="Carolyn Cardoza"
            organization="PwC Canada"
            color="purple"
          />
          <TestimonyCard
            active={activeTestimonyCardNum === 4}
            quote="Participants all enjoyed the event and had nothing but positive things to say. Everyone was really excited to use the app too!"
            author="Abigail Lawrence"
            organization="Conquer Life CIC"
            color="purple"
          />
          <TestimonyCard
            active={activeTestimonyCardNum === 5}
            quote="I mean, it was flawless. It was super simple to use, and it was intuitive how the submissions were shared, and I just got the mission pictures on our end."
            author="Tim Pitts"
            organization="Fundraiser, Leukemia and Lymphoma Society"
            color="purple"
          />
        </TestimonyCardCarousel>
      </Section>

      <div ref={requestQuoteSectionWrapperRef}>
        <Section paddingTop="sm" paddingBot="md">
          <RequestQuoteForm
            id={pricingReqQuoteFormId}
            className={styles.reqQuoteForm}
            classes={{ title: styles.reqQuoteTitle }}
            title="Interested? Request a quote!"
            subtitle="We’ll find a solution tailored to your needs and budget."
          />
        </Section>
      </div>
    </Page>
  );
};

export default Pricing;
